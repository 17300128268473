import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      style={{
        margin: `0 auto`,
        height: `100vh`,
        width: `100%`,
        display: `flex`,
        flexFlow: `row wrap`,
        alignItems: `center`,
        justifyContent: `space-around`,
      }}
    >
        <div style={{ width: `300px`}}>
          <Image />
        </div>
    </div>
  </Layout>
)

/*
<div style={{ width: `300px`}}>
  <h1>Great things coming soon</h1>
  <p>For now, find me <a href="https://adam.alphabetiq.com">here</a>.</p>
</div>
*/


export default IndexPage
